.home_message {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(4, 10, 31, 0.7);
  width: 100%;
  padding: 24px 0;
  .hm_box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    color: #ffffff;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
  }
  .wechat {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 72px;
      height: 72px;
    }
    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 14px;
      margin-top: 4px;
    }
  }
}
